<template>
  <div class="tw-flex tw-flex-col tw-items-center">
    <HelpCenterDrawer v-model="open" />

    <div class="tw-relative tw-w-full tw-max-w-screen-xl">
      <div
        class="tw-fixed tw-bottom-0 tw-right-0 tw-mb-3 tw-mr-3 md:tw-mb-16 md:tw-mr-6 xl:tw-absolute xl:tw-right-0 xl:tw-top-0 xl:tw--mt-32"
      >
        <button
          class="tw-flex tw-cursor-pointer tw-items-center tw-rounded-full tw-bg-gray-800 tw-px-4 tw-py-4 md:tw-rounded-[100px] md:tw-px-6 md:tw-py-4 xl:tw-mt-1"
          type="button"
          @click="toggle"
        >
          <QuestionMark class="md:tw-mr-2" />
          <span class="tw-hidden md:tw-block">
            <div class="tw-text-lg tw-font-semibold tw-text-white">Get Help</div>
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import QuestionMark from './help.svg'

const open = ref(false)
const $analytics = useAnalytics()

const toggle = () => {
  $analytics.trackElementClicked({ elementType: 'button', text: 'get help' })
  open.value = !open.value
}
</script>
