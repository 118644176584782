<template>
  <AppDrawer v-model="open">
    <template #title>Help center</template>
    <GetHelpCenterContent />
  </AppDrawer>
</template>

<script setup lang="ts">
import { useVModel } from '@vueuse/core'

const props = withDefaults(defineProps<{ modelValue: boolean }>(), { modelValue: false })

const emit = defineEmits(['update:modelValue'])

const open = useVModel(props, 'modelValue', emit)
</script>
